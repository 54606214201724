<template>
  <div
    class="modal fade"
    id="modal-confirmation"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modal-confirmation"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-dialog-centered confirmation-modal">
      <div class="modal-content">
        <div class="modal-body">
          <header>
            <img
              src="../../../assets/img/icons/x-icon.svg"
              alt="times-x"
              class="cursor-pointer"
              @click="cancelModal"
            />
          </header>
          <div class="content">
            <img
              src="../../../assets/img/icons/warning.png"
              alt="type"
              v-if="options.type === 'warning'"
              class="icon-type"
            />
            <p :class="`title title-${options.type}`">{{ options.title }}</p>
            <p class="sub-title">{{ options.subTitle }}</p>
          </div>
          <footer>
            <button
              type="button"
              class="btn btn-secondary"
              @click="cancelModal"
            >
              {{ options.textCancelBtn }}
            </button>
            <button type="button" class="btn btn-danger" @click="onConifrm">
              {{ options.textConfirmBtn }}
            </button>
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const { console } = window;
export default {
  name: "ModalConfirmation",
  data() {
    return {
      options: {
        title: "",
        subTitle: "",
        textConfirmBtn: "Hapus",
        textCancelBtn: "Batal",
        type: "warning",
      },
      resolving: null
    };
  },
  methods: {
    show(option) {
      this.options = Object.assign(this.options, option)
      $("#modal-confirmation").modal("show");
      return new Promise((resolve) => {
        this.resolving = resolve
      })
    },
    hide() {
      $("#modal-confirmation").modal("hide");
    },
    cancelModal() {
      this.resolving(false)
      this.hide();
    },
    onConifrm() {
      this.resolving(true)
      this.hide()
    }
  },
};
</script>

<style>
.confirmation-modal {
  & header {
    display: flex;
    justify-content: flex-end;
    & > i {
      font-size: 30px;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    padding: 0 2rem 0 2rem;
    & > .icon-type {
      margin-left: auto;
      margin-right: auto;
    }
    & > .title {
      font-size: 28px; 
      font-weight: 500;
      margin-bottom: 1rem;
      margin-top: 0.5rem;
      text-align: center;

      &.title-warning {
        color: #B4312F;
      }
    }
    & > .sub-title {
      color: #404040;
      font-weight: 400; 
      font-size: 14px;
      margin-bottom: 0.5rem;
      text-align: center;
    }
  }
  & footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
  }
}
</style>
